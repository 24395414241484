import React from 'react';
import MaterialTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import IntlMessages from '../../../util/IntlMessages';

const TextField = props =>
    <MaterialTextField
        label={props.name? <IntlMessages id={props.name}/> : null}
        value={props.value}
        fullWidth
        margin="normal"
        variant="outlined"
        {...props}
    />;

TextField.propTypes = {
    // name: PropTypes.element.isRequired,
    value: PropTypes.element.isRequired,
};

export default TextField;
