import appLocaleData from 'react-intl/locale-data/pt';
import ptMessages from '../locales/pt_PT.json';

const ptLang = {
  messages: {
    ...ptMessages
  },
  locale: 'pt',
  data: appLocaleData
};
export default ptLang;
