import enLang from "./entries/en_US";
import deLang from "./entries/de_DE";
import esLang from "./entries/es_ES";
import frLang from "./entries/fr_FR";
import ptLang from "./entries/pt_PT";
import Lang from "./entries/es_ES";
import { addLocaleData } from "react-intl";

const AppLocale = {
  en: enLang,
  de: deLang,
  es: esLang,
  fr: frLang,
  pt: ptLang,
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.de.data);
addLocaleData(AppLocale.es.data);
addLocaleData(AppLocale.pt.data);
addLocaleData(AppLocale.fr.data);

export default AppLocale;
