import React from "react";
import CircularProgress from "../../components/CircularProgress/index";
import Snackbar from "@material-ui/core/Snackbar";
import Auxiliary from "util/Auxiliary";
import { connect } from "react-redux";
import { hideMessage } from "../../actions/Common";
import Notification from "../Notification/Notification";

class InfoView extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.error || nextProps.message) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 3000);
    }
  }

  render() {
    const { error, loading, message } = this.props;
    const open = error !== "" || message !== "";
    let showMessage = message;
    if (error) {
      showMessage = error;
    }

    return (
      <Auxiliary>
        {loading && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}

        <Notification showMessage={open} message={showMessage} />
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ commonData }) => {
  const { error, loading, message } = commonData;
  return { error, loading, message };
};

export default connect(mapStateToProps, { hideMessage })(InfoView);
