import appLocaleData from 'react-intl/locale-data/es';
import esMessages from '../locales/es_ES.json';

const esLang = {
  messages: {
    ...esMessages
  },
  locale: 'es',
  data: appLocaleData
};
export default esLang;
