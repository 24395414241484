import React, { Component, useContext } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "../../components/Button/Button";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import { BRAND_COLOR } from "../../constants/ThemeColors";
import { RoleConsumer } from "../../app/contexts/RoleContext";

class SidenavContent extends Component {
  state = {
    expanded: "",
  };

  handleExpanded = (expanded) => {
    if (expanded !== this.state.expanded) {
      this.setState({
        expanded,
      });
    }
  };

  render() {
    const pathname = this.props.history.location.pathname;
    let { expanded } = this.state;

    const isSuperAdmin = (user) =>
      user && user.privileges.find((p) => p.id === "ADMIN");
    const isAdmin = (user) =>
      user && user.privileges.find((p) => p.id === "DISTRIBUTOR");

    return (
      <RoleConsumer>
        {(user) => (
          <CustomScrollbars className=" scrollbar">
            <ul className="nav-menu">
              <li className="nav-header">
                <IntlMessages id="welcome" />
              </li>

              {isSuperAdmin(user) && (
                <>
                  <li className="menu no-arrow">
                    <NavLink
                      to="/app/home"
                      activeStyle={styles.activeNavLink}
                      onClick={() => this.handleExpanded("home")}
                    >
                      <i className="zmdi zmdi-coffee zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="pages.home" />{" "}
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={
                      pathname.startsWith("/app/users") || expanded === "users"
                        ? "menu collapse-box open"
                        : "menu collapse-box"
                    }
                    onClick={() => this.handleExpanded("users")}
                  >
                    <Button className={"text-light mt-0"}>
                      <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="sidebar.users" />
                      </span>
                    </Button>
                    <ul className="sub-menu">
                      <li>
                        <NavLink
                          to="/app/users/admins"
                          activeStyle={styles.activeSubNavLink}
                        >
                          <span className="nav-text">
                            <IntlMessages id="sidebar.admins" />
                          </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/app/users/distributors"
                          activeStyle={styles.activeSubNavLink}
                        >
                          <span className="nav-text">
                            <IntlMessages id="sidebar.distributors" />
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="menu no-arrow">
                    <NavLink
                      to="/app/distributorBusiness"
                      activeStyle={styles.activeNavLink}
                      onClick={() => this.handleExpanded("distributorBusiness")}
                    >
                      <i className="zmdi zmdi-store zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="sidebar.distributorBusiness" />
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={
                      pathname.startsWith("/app/settings") ||
                      expanded === "settings"
                        ? "menu collapse-box open"
                        : "menu collapse-box"
                    }
                    onClick={() => this.handleExpanded("settings")}
                  >
                    <Button className={"text-light mt-0"}>
                      <i className="zmdi zmdi-settings zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="sidebar.settings" />
                      </span>
                    </Button>

                    <ul className="sub-menu">
                      <li>
                        <NavLink
                          to="/app/settings/alerts"
                          activeStyle={styles.activeSubNavLink}
                        >
                          <span className="nav-text">
                            <IntlMessages id="sidebar.alerts" />
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </>
              )}
              {isAdmin(user) && (
                <>
                  {isSuperAdmin(user) && (
                    <li className="nav-header">
                      <IntlMessages id="distributorZone" />
                    </li>
                  )}
                  <li className="menu no-arrow">
                    <NavLink
                      to="/app/home"
                      activeStyle={styles.activeNavLink}
                      onClick={() => this.handleExpanded("home")}
                    >
                      <i className="zmdi zmdi-coffee zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="pages.home" />{" "}
                      </span>
                    </NavLink>
                  </li>
                  {!isSuperAdmin(user) && (
                    <li className="menu no-arrow">
                      <NavLink
                        to="/app/allDevices"
                        activeStyle={styles.activeNavLink}
                        onClick={() => this.handleExpanded("devices")}
                      >
                        <i className="zmdi zmdi-device-hub zmdi-hc-fw" />
                        <span className="nav-text">
                          <IntlMessages id="pages.devices" />{" "}
                        </span>
                      </NavLink>
                    </li>
                  )}
                  <li className="menu no-arrow">
                    <NavLink
                      to="/app/business"
                      activeStyle={styles.activeNavLink}
                      onClick={() => this.handleExpanded("business")}
                    >
                      <i className="zmdi zmdi-store zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="pages.business" />{" "}
                      </span>
                    </NavLink>
                  </li>
                  <li className="menu no-arrow">
                    <NavLink
                      to="/app/technician"
                      activeStyle={styles.activeNavLink}
                      onClick={() => this.handleExpanded("technicians")}
                    >
                      <i className="zmdi zmdi-account zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="sidebar.technicians" />
                      </span>
                    </NavLink>
                  </li>
                  <li
                    className={
                      pathname.startsWith("/app/settings") ||
                      expanded === "settings"
                        ? "menu collapse-box open"
                        : "menu collapse-box"
                    }
                    onClick={() => this.handleExpanded("settings")}
                  >
                    <Button className={"text-light mt-0"}>
                      <i className="zmdi zmdi-settings zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="sidebar.settings" />
                      </span>
                    </Button>
                    <ul className="sub-menu">
                      <li>
                        <NavLink
                          to="/app/settings/alerts"
                          activeStyle={styles.activeSubNavLink}
                        >
                          <span className="nav-text">
                            <IntlMessages id="sidebar.alerts" />
                          </span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </>
              )}
            </ul>
          </CustomScrollbars>
        )}
      </RoleConsumer>
    );
  }
}

export default withRouter(SidenavContent);

const styles = {
  activeNavLink: {
    fontWeight: "500",
    color: BRAND_COLOR,
  },
  activeSubNavLink: {
    backgroundColor: BRAND_COLOR,
    fontWeight: "500",
  },
};
