import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import InfoView from 'components/InfoView';
import {userSignUp} from '../actions/Auth';
import customTheme from "./themes/customTheme";
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {PRIMARY_COLOR} from "../constants/ThemeColors";

class SignUp extends React.Component {
  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      password: ''
    }
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 3000);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const {name, email, password} = this.state;
    const applyTheme = createMuiTheme(customTheme());

    return (
        <MuiThemeProvider theme={applyTheme}>
          <div
              className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div className="app-login-main-content">
              <div className="app-logo-content d-flex align-items-center justify-content-center" style={{backgroundColor: PRIMARY_COLOR}}>
                <Link className="logo-lg" to="/" title="Jambo">
                  <img src={require("assets/images/logo.png")} alt="jambo" title="logo" className={'ml-auto mr-auto'} style={{width: '95%'}}/>
                </Link>
              </div>

              <div className="app-login-content">
                <div className="app-login-header">
                  <h1><IntlMessages id="appModule.createAccount"/></h1>
                </div>

                <div className="app-login-form">
                  <form method="post" action="/">
                    <TextField
                        type="text"
                        label={<IntlMessages id="appModule.name"/>}
                        onChange={(event) => this.setState({name: event.target.value})}
                        fullWidth
                        defaultValue={name}
                        margin="normal"
                        className="mt-0 mb-2"
                    />

                    <TextField
                        type="email"
                        onChange={(event) => this.setState({email: event.target.value})}
                        label={<IntlMessages id="appModule.email"/>}
                        fullWidth
                        defaultValue={email}
                        margin="normal"
                        className="mt-0 mb-2"
                    />

                    <TextField
                        type="password"
                        onChange={(event) => this.setState({password: event.target.value})}
                        label={<IntlMessages id="appModule.password"/>}
                        fullWidth
                        defaultValue={password}
                        margin="normal"
                        className="mt-0 mb-4"
                    />

                    <div className="mb-3 d-flex align-items-center justify-content-between">
                      <Button variant="contained" onClick={() => {
                        this.props.userSignUp({name, email, password});
                      }} color="primary">
                        <IntlMessages
                            id="appModule.regsiter"/>
                      </Button>
                      <Link to="/signin">
                        <IntlMessages id="signUp.alreadyMember"/>
                      </Link>
                    </div>

                  </form>
                </div>
              </div>

            </div>
            <InfoView/>
          </div>
        </MuiThemeProvider>
    )
  }
}

const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};

export default connect(mapStateToProps, {userSignUp})(SignUp);
