import React, { Component } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "../assets/vendors/style";
import defaultTheme from "./themes/defaultTheme";
import AppLocale from "../lngProvider";
import MainApp from "../app/index";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { getUser, setInitUrl, refreshToken } from "../actions/Auth";
import { hideAuthLoader, showAuthLoader } from "../actions/Common";
import RTL from "../util/RTL";
import axios from "../util/Api";
import asyncComponent from "../util/asyncComponent";
import { RoleProvider } from "../app/contexts/RoleContext";
import AppLoader from "../components/AppLoader/AppLoader";
import Recover from "./Recover";
import ResetPassword from "./ResetPassword";
import ValidateEmail from "./ValidateEmail";

const RestrictedRoute = ({ component: Component, token, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      token ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

class App extends Component {
  state = { counter: true };

  componentDidMount() {
    this.props.loader &&
      setTimeout(() => {
        this.setState({ counter: false });
      }, 1500);

    if (this.props.token) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.props.token["access_token"];
      this.props.setInitUrl(this.props.history.location.pathname);

      const hasRefreshToken = this.props.token["refresh_token"];
      const { refreshToken } = this.props;

      axios.interceptors.response.use(
        function(config) {
          return config;
        },
        function(error) {
          const { config } = error;

          if (
            config &&
            !config.url.endsWith("refresh") &&
            (error.response.status === 403 || error.response.status === 401)
          ) {
            if (!hasRefreshToken) {
              localStorage.removeItem("token");
              localStorage.removeItem("authUser");
              window.location.href = "/";
              return;
            }
            return new Promise((resolve) => {
              refreshToken((token) => {
                config.headers.Authorization = `Bearer ${token}`;
                resolve(axios(config));
              });
            });
          }
          return Promise.reject(error);
        }
      );
      this.props.getUser();
    } else {
      if (this.props.initURL === "") {
        this.props.setInitUrl("/");
      }
    }
  }

  render() {
    const {
      match,
      location,
      locale,
      token,
      initURL,
      isDirectionRTL,
      authUser,
      hideAuthLoader,
    } = this.props;

    if (location.pathname.startsWith("/signin") && authUser) {
      hideAuthLoader();
      return <Redirect to={"/app"} />;
    }

    if (location.pathname === "/") {
      if (token === null) {
        return <Redirect to={"/signin"} />;
      } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
        return <Redirect to={"/app/"} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }
    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = "rtl";
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
      applyTheme.direction = "ltr";
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <RoleProvider value={authUser}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <IntlProvider
              locale={currentAppLocale.locale}
              messages={currentAppLocale.messages}
            >
              <RTL>
                <div className="app-main">
                  {!this.props.location.pathname.startsWith("/signin") &&
                  this.state.counter ? (
                    <AppLoader />
                  ) : (
                    <Switch>
                      <RestrictedRoute
                        path={`${match.url}app`}
                        token={token}
                        component={MainApp}
                      />
                      <Route path="/signin" component={SignIn} />
                      <Route path="/recover" component={Recover} />
                      <Route path="/reset/:token" component={ResetPassword} />
                      <Route
                        path="/validate/:token"
                        component={ValidateEmail}
                      />
                      <Route path="/signup" component={SignUp} />
                      <Route
                        component={asyncComponent(() =>
                          import("../components/Error404")
                        )}
                      />
                    </Switch>
                  )}
                </div>
              </RTL>
            </IntlProvider>
          </MuiPickersUtilsProvider>
        </RoleProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { sideNavColor, locale, isDirectionRTL } = settings;
  const { authUser, token, initURL, loader } = auth;
  return {
    sideNavColor,
    token,
    locale,
    isDirectionRTL,
    authUser,
    initURL,
    loader,
  };
};

export default connect(mapStateToProps, {
  setInitUrl,
  getUser,
  hideAuthLoader,
  refreshToken,
})(App);
