import React from 'react';
import MuiButton from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core/styles";
import { BRAND_COLOR } from "../../constants/ThemeColors";

const Button = props => {

  const ThemeButton = withStyles(theme => ({
    root: {
      ...props.bg && {backgroundColor: props.bg},
      ...props.size && {size: props.size},
      ...props.clr && {color: props.clr},
      ...props.borderClr ? {border: '2px solid' + props.borderClr } : {border: '2px solid' + props.bg },
      '&:hover': {
        backgroundColor: '#858585',
        color: '#ffffff',
        size: props.size,
        border: props.bg && '2px solid #858585',
      },
      '&:disabled': {
        color: '#a3a3a3',
        backgroundColor: '#dcdcdc',
        border: '2px solid #dcdcdc',
        size: props.size,
        cursor: 'not-allowed'
      },
    },
  }))(MuiButton);

  return <ThemeButton {...props} />;

};

export default Button;
