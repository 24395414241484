import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import IntlMessages from "util/IntlMessages";
import InfoView from "../components/InfoView";
import customTheme from "./themes/customTheme";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { PRIMARY_COLOR } from "../constants/ThemeColors";
import { userSignIn } from "../actions/Auth";
import { showAuthLoader } from "../actions/Common";
import CircularProgress from "../components/CircularProgress";
import TextField from "../components/Form/TextField";
import Button from "../components/Button/Button";

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      form: {
        email: "",
        password: "",
      },
      isValid: true,
      validations: {
        email: true,
        password: true,
      },
      loading: false,
    };
  }

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.signInHandler();
    }
  };

  signInHandler = () => {
    if (this.state.form.email && this.state.form.password) {
      this.props.showAuthLoader();
      this.props.userSignIn({
        email: this.state.form.email,
        password: this.state.form.password,
      });
    } else {
      console.log("error in sign in");
    }
  };

  validate = (name, value) => {
    switch (name) {
      case "email":
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return value && pattern.test(value);
      case "password":
        return value.length > 5;
      default:
        return true;
    }
  };

  handleChange = (name) => (event) => {
    let form = {
      ...this.state.form,
    };

    form[name] = event.target.value;

    let validations = {
      ...this.state.validations,
    };

    validations[name] = this.validate(name, form[name]);

    let isValid = true;

    for (let value of Object.entries(form)) {
      isValid = isValid && this.validate(value[0], value[1]);
      if (!isValid) {
        break;
      }
    }

    this.setState({
      form,
      validations,
      isValid,
    });
  };

  render() {
    const { form, isValid, validations, loading } = this.state;
    const { showMessage, loader, alertMessage } = this.props;

    const applyTheme = createMuiTheme(customTheme());

    return (
      <MuiThemeProvider theme={applyTheme}>
        {!loader && !loading ? (
          <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div className="app-login-main-content">
              <div
                className="app-logo-content d-flex align-items-center justify-content-center"
                style={{ backgroundColor: "#666" }}
              >
                <Link className="logo-lg" to="/" title="Jambo">
                  <img
                    src={require("assets/images/logo-white.png")}
                    alt="jambo"
                    title="logo"
                    className={"ml-auto mr-auto"}
                    style={{ width: "95%" }}
                  />
                </Link>
              </div>

              <div className="app-login-content">
                <div className="app-login-header mb-4">
                  <h1>
                    <IntlMessages id="signInTitle" />
                  </h1>
                </div>

                <div className="app-login-form">
                  <form>
                    <fieldset>
                      <TextField
                        label={<IntlMessages id="appModule.email" />}
                        value={form.email}
                        type="email"
                        onChange={this.handleChange("email")}
                        // helperText={validations.email ?
                        //   ''
                        //   :
                        //   <IntlMessages id="emailError"/>}
                        // error={!validations.email}
                        onKeyDown={this.onKeyDown}
                      />
                      <TextField
                        value={form.password}
                        type="password"
                        label={<IntlMessages id="appModule.password" />}
                        helperText={
                          validations.password ? (
                            ""
                          ) : (
                            <IntlMessages id="passwordError" />
                          )
                        }
                        error={!validations.password}
                        onChange={this.handleChange("password")}
                        onKeyDown={this.onKeyDown}
                      />

                      <div className="mb-3 d-flex align-items-center justify-content-between">
                        <Button
                          onClick={this.signInHandler}
                          variant="contained"
                          color="primary"
                        >
                          <IntlMessages id="appModule.signIn" />
                        </Button>

                        <Link to="/recover">
                          <IntlMessages id="signIn.recover" />
                        </Link>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="loader-view  ml-auto mr-auto">
            <CircularProgress color="secondary" />
          </div>
        )}
        {/*
          <InfoView />
        */}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ auth, commonData }) => {
  const { authUser } = auth;
  const { loading } = commonData;
  return { authUser, loader: loading };
};

export default connect(mapStateToProps, { userSignIn, showAuthLoader })(SignIn);
