import appLocaleData from 'react-intl/locale-data/fr';
import frMessages from '../locales/fr_FR.json';

const frLang = {
  messages: {
    ...frMessages
  },
  locale: 'fr',
  data: appLocaleData
};
export default frLang;
