import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../components/Header/index";
import Sidebar from "../containers/SideNav/index";
import Footer from "../components/Footer";
import Tour from "../components/Tour/index";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "../constants/ActionTypes";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "../components/TopNav";
import { RoleConsumer } from "../app/contexts/RoleContext";
import { createMuiTheme } from "@material-ui/core";
import customTheme from "../containers/themes/customTheme";
import { ThemeProvider } from "@material-ui/styles";
import { MiscProvider } from "./contexts/MiscData";

const NotFound = asyncComponent(() => import("../components/Error404"));

const SuperAdminRoute = ({ component: Component, ...rest }) => (
  <RoleConsumer>
    {(user) => (
      <Route
        render={(props) => {
          props = { ...rest, ...props };
          return user && user.privileges.find((p) => p.id === "ADMIN") ? (
            <Component {...props} />
          ) : (
            <NotFound />
          );
        }}
        {...rest}
      />
    )}
  </RoleConsumer>
);

const AdminRoute = ({ component: Component, ...rest }) => (
  <RoleConsumer>
    {(user) => (
      <Route
        render={(props) => {
          props = { ...rest, ...props };
          return user && user.privileges.find((p) => p.id === "DISTRIBUTOR") ? (
            <Component {...props} />
          ) : (
            <NotFound />
          );
        }}
        {...rest}
      />
    )}
  </RoleConsumer>
);

class App extends React.Component {
  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    const applyTheme = createMuiTheme(customTheme());
    return (
      <MiscProvider>
        <ThemeProvider theme={applyTheme}>
          <div className={`app-container ${drawerStyle}`}>
            {/*<Tour/>*/}

            <Sidebar />
            <div className="app-main-container">
              <div
                className={`app-header ${
                  navigationStyle === HORIZONTAL_NAVIGATION
                    ? "app-header-horizontal"
                    : ""
                }`}
              >
                {navigationStyle === HORIZONTAL_NAVIGATION &&
                  horizontalNavPosition === ABOVE_THE_HEADER && (
                    <TopNav styleName="app-top-header" />
                  )}
                <Header />
                {navigationStyle === HORIZONTAL_NAVIGATION &&
                  horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
              </div>

              <main className="app-main-content-wrapper">
                <div className="app-main-content">
                  <Switch>
                    {/*PUBLIC ROUTES*/}
                    <Route
                      path={`${match.url}/profile`}
                      exact
                      component={asyncComponent(() =>
                        import("./routes/Profile")
                      )}
                    />

                    {/*ADMIN ROUTES*/}
                    {this.props.auth &&
                    this.props.auth.authUser.privileges.find(
                      (p) => p.id === "ADMIN"
                    ) ? (
                      <React.Fragment>
                        <SuperAdminRoute
                          path={`${match.url}/`}
                          exact
                          component={() => <Redirect to={`/app/home`} />}
                        />
                        <SuperAdminRoute
                          path={`${match.url}/home`}
                          component={asyncComponent(() =>
                            import("./routes/SuperAdminRoutes/Home")
                          )}
                        />

                        <SuperAdminRoute
                          path={`${match.url}/users/Admins`}
                          component={asyncComponent(() =>
                            import("./routes/SuperAdminRoutes/Admins/Admins")
                          )}
                        />

                        <SuperAdminRoute
                          path={`${match.url}/users/Distributors`}
                          component={asyncComponent(() =>
                            import(
                              "./routes/SuperAdminRoutes/Distributors/Distributors"
                            )
                          )}
                        />

                        <SuperAdminRoute
                          path={`${match.url}/settings/alerts`}
                          component={asyncComponent(() =>
                            import("./routes/Alerts")
                          )}
                        />

                        <SuperAdminRoute
                          path={`${match.url}/settings/deviceTypes`}
                          component={asyncComponent(() =>
                            import("./routes/SuperAdminRoutes/DeviceTypes")
                          )}
                        />

                        <SuperAdminRoute
                          path={`${match.url}/distributorBusiness`}
                          exact
                          component={asyncComponent(() =>
                            import(
                              "./routes/SuperAdminRoutes/DistributorBusiness"
                            )
                          )}
                        />

                        <SuperAdminRoute
                          path={`${match.url}/distributorBusiness/devices/:id`}
                          exact
                          component={asyncComponent(() =>
                            import(
                              "./routes/SuperAdminRoutes/DistributorBusiness/Components/BusinessDevices"
                            )
                          )}
                        />

                        <SuperAdminRoute
                          path={`${match.url}/distributorBusiness/:id`}
                          exact
                          component={asyncComponent(() =>
                            import(
                              "./routes/SuperAdminRoutes/DistributorBusiness/Components/BusinessPage"
                            )
                          )}
                        />

                        <SuperAdminRoute
                          path={`${match.url}/devices`}
                          component={asyncComponent(() =>
                            import("./routes/SuperAdminRoutes/Devices")
                          )}
                        />
                      </React.Fragment>
                    ) : (
                      /* DISTRIBUTOR ROUTES */
                      <React.Fragment>
                        <AdminRoute
                          path={`${match.url}/`}
                          exact
                          component={() => <Redirect to={`/app/home`} />}
                        />

                        <AdminRoute
                          path={`${match.url}/home`}
                          component={asyncComponent(() =>
                            import("./routes/SuperAdminRoutes/Home")
                          )}
                        />

                        <AdminRoute
                          path={`${match.url}/technician`}
                          exact
                          component={asyncComponent(() =>
                            import(
                              "./routes/AdminRoutes/Technicians/Technicians"
                            )
                          )}
                        />
                        <AdminRoute
                          path={`${match.url}/technician/:id`}
                          exact
                          component={asyncComponent(() =>
                            import(
                              "./routes/AdminRoutes/Technicians/Components/UserPage"
                            )
                          )}
                        />

                        <AdminRoute
                          path={`${match.url}/business`}
                          exact
                          component={asyncComponent(() =>
                            import("./routes/AdminRoutes/Business")
                          )}
                        />

                        <AdminRoute
                          path={`${match.url}/business/:id`}
                          exact
                          component={asyncComponent(() =>
                            import(
                              "./routes/AdminRoutes/Business/Components/BusinessPage"
                            )
                          )}
                        />

                        <AdminRoute
                          path={`${match.url}/allDevices`}
                          exact
                          component={asyncComponent(() =>
                            import("./routes/AdminRoutes/Devices")
                          )}
                        />

                        <AdminRoute
                          path={`${match.url}/all-alert-devices`}
                          exact
                          component={asyncComponent(() =>
                            import("./routes/AdminRoutes/DevicesWithAlerts")
                          )}
                        />

                        <AdminRoute
                          path={`${match.url}/total-coffe-business`}
                          exact
                          component={asyncComponent(() =>
                            import("./routes/AdminRoutes/TotalCoffeBusiness")
                          )}
                        />

                        <AdminRoute
                          path={`${match.url}/total-coffe-business/:id`}
                          exact
                          component={asyncComponent(() =>
                            import(
                              "./routes/AdminRoutes/TotalCoffeBusiness/Components/BusinessPage"
                            )
                          )}
                        />

                        <AdminRoute
                          path={`${match.url}/device/:id`}
                          exact
                          component={asyncComponent(() =>
                            import(
                              "./routes/AdminRoutes/Devices/Components/EditDevice"
                            )
                          )}
                        />

                        <AdminRoute
                          path={`${match.url}/device/:deviceid/visit/:visitid`}
                          exact
                          component={asyncComponent(() =>
                            import(
                              "./routes/AdminRoutes/Devices/Components/ViewVisit"
                            )
                          )}
                        />

                        <AdminRoute
                          path={`${match.url}/device/:deviceid/alert/:alertid`}
                          exact
                          component={asyncComponent(() =>
                            import(
                              "./routes/AdminRoutes/Devices/Components/ViewAlert"
                            )
                          )}
                        />

                        <AdminRoute
                          path={`${match.url}/settings/alerts`}
                          component={asyncComponent(() =>
                            import("./routes/Alerts")
                          )}
                        />
                      </React.Fragment>
                    )}

                    <Route
                      component={asyncComponent(() =>
                        import("../components/Error404")
                      )}
                    />
                  </Switch>
                </div>
                {/*<Footer/>*/}
              </main>
            </div>
          </div>
        </ThemeProvider>
      </MiscProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition, auth };
};
export default withRouter(connect(mapStateToProps)(App));
