import React, { useEffect, useState } from "react";
import ReactNotification from "react-notifications-component";
import "./Notification.css";

const Notification = props => {

  const [notificationDOMRef, setNotificationDOMRef] = useState( React.createRef());

  if(props.showMessage && notificationDOMRef.current){
    notificationDOMRef.current.addNotification({
      title: props.title || "",
      message:  props.message || "Error",
      type: props.type || "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      // content: <div className={'notification-item'}>yuyu</div>
    });
  }

  return (
    <ReactNotification ref={notificationDOMRef} />
  )

};


export default Notification;
