import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL, IS_FINISHED,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../constants/ActionTypes";
import axios from '../util/Api'
import { showAuthLoader, hideAuthLoader } from "../actions";
import { ADMIN_ID, DISTRIBUTOR_ID } from "../constants/strings";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({name, email, password}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/register', {
          email: email,
          password: password
        }
    ).then(({data}) => {
      if (data) {
        localStorage.setItem("token", JSON.stringify(data));
        axios.defaults.headers.common['access-token'] = "Bearer " + data.access_token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, token: data.access_token, refreshToken: data.refresh_token});
        dispatch({type: USER_DATA, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const userSignIn = ({email, password}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('/auth/signin', {
        email: email,
        password: password,
        privileges: [ADMIN_ID, DISTRIBUTOR_ID]
      }
    ).then(({data}) => {
      if (data) {
        localStorage.setItem("token", JSON.stringify(data));
        axios.defaults.headers.common['Authorization'] = "Bearer " + data.access_token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, token: data.access_token, refreshToken: data.refresh_token});
        dispatch(getUser());
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      if (error.response && error.response.status === 404) error.message = 'User not found';
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
      hideAuthLoader();
    });
  }
};

export const refreshToken = () => {
  const params = new URLSearchParams();
  params.append('refresh_token', JSON.parse(localStorage.getItem('token'))['refresh_token']);
  params.append('client', 'Admin dashboard');
  params.append('version', '1.0');

  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('/auth/refresh', params
    ).then (({data}) => {
      if (data){
        localStorage.setItem("token", JSON.stringify(data));
        axios.defaults.headers.common['Authorization'] = "Bearer " + data.access_token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, token: data.access_token, refreshToken: data.refresh_token});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
        dispatch(userSignOut());
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      dispatch(userSignOut());
    })
  }
};

export const getUser = (fromProfile) => {
  // showAuthLoader();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.get('/user/me',
    ).then(({data}) => {
      if (data) {
        localStorage.setItem("authUser", JSON.stringify(data));
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data});
        fromProfile && dispatch({type: IS_FINISHED});
        return data;
      } else {
        dispatch({type: FETCH_ERROR, payload: data});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
      return error;
    });
  }
};

export const userSignOut = () => {
  return (dispatch) => {
    try {
      localStorage.removeItem('token');
      localStorage.removeItem("authUser");
      dispatch({ type: SIGNOUT_USER_SUCCESS });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
    }
  }
};
