// -- GLOBAL
// export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:8080';
export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:8080';
export const API_VERSION_PRIVATE = process.env.REACT_APP_API_VERSION_PRIVATE || '/api/private/v1';
export const API_KEY_PRIVATE = process.env.REACT_APP_API_KEY_PRIVATE || 'test';

// -- CUSTOM
export const BRAND_COLOR = '#4A6A1D';
export const SECONDARY_COLOR = '#00E2BD';
export const BASE_NAME = 'Quality Espresso';

export const PRIVILEGES = ['ADMIN', 'DISTRIBUTOR', 'TECHNICIAN'];

export const ADMIN_ID = 'ADMIN';
export const DISTRIBUTOR_ID = "DISTRIBUTOR";
export const TECHICIAN_ID = 'TECHNICIAN';
