import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { connect } from "react-redux";
import { switchLanguage } from "../../actions";
import {
  french,
  spanish,
  english,
  german,
  portuguese,
} from "../../reducers/Settings";
import IntlMessages from "../../util/IntlMessages";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const SelectLanguage = (props) => {
  const classes = useStyles();

  const [state, setState] = React.useState(props?.locale?.locale ?? "es");

  const handleChange = (event) => {
    setState(event.target.value);
    if (event.target.value === "fr") {
      props.switchLanguage(french);
    } else if (event.target.value === "es") {
      props.switchLanguage(spanish);
    } else if (event.target.value === "en") {
      props.switchLanguage(english);
    } else if (event.target.value === "de") {
      props.switchLanguage(german);
    } else if (event.target.value === "pt") {
      props.switchLanguage(portuguese);
    }
  };

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      size={"small"}
    >
      <InputLabel htmlFor="outlined-idioma-native-simple">
        <IntlMessages id="select.language" />
      </InputLabel>
      <Select
        native
        value={state}
        onChange={handleChange}
        label={<IntlMessages id="select.language" />}
        inputProps={{
          name: "Idioma",
          id: "outlined-idioma-native-simple",
        }}
      >
        <option value={"es"}>Español</option>
        <option value={"en"}>English</option>
        <option value={"fr"}>Français</option>
        <option value={"de"}>Deutsch</option>
        <option value={"pt"}>Português</option>
      </Select>
    </FormControl>
  );
};

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};

export default connect(mapStateToProps, { switchLanguage })(SelectLanguage);
