import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  IS_FINISHED, IS_NOT_FINISHED,
  SHOW_MESSAGE
} from "../constants/ActionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  message: '',
  isFinished: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {...state, error: '', message: '', loading: true};
    }
    case FETCH_SUCCESS: {
      return {...state, error: '', message: '', loading: false};
    }
    case SHOW_MESSAGE: {
      return {...state, error: '', message: action.payload, loading: false};
    }
    case FETCH_ERROR: {
      return {...state, loading: false, error: action.payload, message: ''};
    }
    case HIDE_MESSAGE: {
      return {...state, loading: false, error: '', message: ''};
    }
    case IS_FINISHED: {
      return { ...state, isFinished: true };
    }
    case IS_NOT_FINISHED: {
      return { ...state, isFinished: false };
    }

    default:
      return state;
  }
}
