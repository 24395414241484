import React, {useEffect, useState} from "react";
import axios from '../../util/Api';
import {connect} from "react-redux";

export const MiscDataContext = React.createContext({});

const mapStateToProps = ({auth}) => {
  return {auth}
};

export const MiscProvider = connect(mapStateToProps)((props) =>{

    const [business, setBusiness] = useState([]);
    const [alertTypes, setAlertTypes] = useState( []);
    const [technicians, setTechnicians] = useState([]);
    const [deviceType, setDeviceType] = useState([]);

    const getData = () => {
        getBusiness();
        getAlertTypes();
        getDeviceType();
        props.auth.authUser.privileges.map( p => p.id === 'DISTRIBUTOR' && getTechnicians())
    };

    useEffect( () =>{
        getData();
    }, []);

    const getBusiness = () =>{
        axios.get('/business/all')
            .then( res => {
                setBusiness(res.data);
            })
            .catch( err =>{
                console.log(err)
            })
    };

    const getAlertTypes = () =>{
        axios.get('/alertType')
            .then( res => {
                setAlertTypes(res.data.content);
            })
            .catch( err =>{
                // console.log(err)
            })
    };

    const getTechnicians = () =>{
        axios.get('/user/technician')
            .then( res => {
                setTechnicians(res.data);
            })
            .catch( err =>{
                // console.log(err)
            })
    };

  const getDeviceType = () =>{
    axios.get('/deviceType')
      .then( res => {
        setDeviceType(res.data.content);
      })
      .catch( err =>{
        // console.log(err)
      })
  };

    return (
        <MiscDataContext.Provider
            value={{business, alertTypes, technicians, deviceType, getData, getBusiness}}
        >
            {props.children}
        </MiscDataContext.Provider>
    )
});

export const MiscConsumer = MiscDataContext.Consumer;
