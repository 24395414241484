import React, {useRef, useEffect}  from 'react';
import {connect} from 'react-redux';
import Button from '../components/Button/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import CssBaseline from "@material-ui/core/CssBaseline";
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {BASE_NAME, BRAND_COLOR, SECONDARY_COLOR} from "../constants/strings";

import {
    userSignUp,
} from '../actions/Auth';
import customTheme from "./themes/customTheme";
import { BLACK_COLOR, PRIMARY_COLOR } from "../constants/ThemeColors";
import axios from "../util/Api";
import Notification from '../components/Notification/Notification'
import { TextField } from "@material-ui/core";




class Recover extends React.Component {
    constructor() {
        document.title = BASE_NAME +' - Recupera tu contraseña';
        super();
        this.state = {
            form: {
                email: '',
            },
            isValid: false,
            validations: {
                email: true
            },
            loading: false,
            notification : {
                showNotification: false,
                text: "aaaaa",
                type: 'default'
            }

        }
    }


    componentDidUpdate() {
        if (this.props.showMessage) {
            setTimeout(() => {
                this.props.hideMessage();
            }, 3000);
        }
        if (this.props.authUser !== null) {
            this.props.history.push('/');
        }
    }


    validate = (name, value) => {
        switch (name){
            case 'email':
                const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
                return value && pattern.test(value);
            case 'password':
                return value.length>5;
            default:
                return true;
        }

    };


    handleChange = name => event => {

        let form = {
            ...this.state.form
        };

        form[name] = event.target.value;

        let validations = {
            ...this.state.validations
        };

        validations[name] = this.validate(name, form[name]);

        let isValid = true;

        for (let value of Object.entries(form)){
            isValid = isValid && this.validate(value[0], value[1]);
            if (!isValid){break;}
        }

        this.setState({
            form,
            validations,
            isValid
        });
    };


    onKeyDown = (event) => {
        if (event.key === 'Enter' && this.state.isValid) {
            event.preventDefault();
            event.stopPropagation();
            this.handleRecover();
        }
    };


    handleRecover = () => {

        this.setState({
            loading: true,
        });

        axios.get('/auth/resetpwd/request?email='+this.state.form.email.toLowerCase())
            .then( res => {
                // console.log(res.data);
                this.setState({
                    loading: false,
                    notification: {
                        showNotification: true,
                        text: "Se ha enviado un mensaje al correo indicado",
                        type: 'default'
                    },
                    email: ''
                });
            })
            .catch( err => {
                this.setState({
                    loading: false,
                    notification: {
                        showNotification: true,
                        text: "Introduzca un correo electrónico correcto",
                        type: 'danger'
                    },
                });
            })
        this.setState({
            loading: false,
            showNotification: false,
        } );

    };



    render() {
        const {
            email,
            isValid,
            validations,
            loading
        } = this.state;
        const {showMessage, loader, alertMessage} = this.props;
        const applyTheme = createMuiTheme(customTheme());

        return (
            <MuiThemeProvider theme={applyTheme}>
                <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                    <div className="app-login-main-content bg-white">
                        <div className="app-logo-content d-flex align-items-center justify-content-center" style={{backgroundColor: '#666'}}>
                            <Link className="logo-lg" to="/" title="Jambo">
                                <img src={require("assets/images/logo-white.png")} alt="jambo" title="logo" className={'ml-auto mr-auto'} style={{width: '95%'}}/>
                            </Link>
                        </div>

                        <div className="app-login-content">
                            <div className="app-login-header">
                                <h1>{<IntlMessages id="recoverTitle"/>}</h1>
                            </div>

                            <div className="app-login-form">
                                <form>

                                    <TextField
                                        value={email}
                                        type="email"
                                        onChange={this.handleChange('email')}
                                        label={<IntlMessages id="emailAddress"/>}
                                        fullWidth
                                        helperText={validations.email ?
                                            ''
                                            :
                                            <IntlMessages id="emailError"/>}
                                        error={!validations.email}
                                        margin="normal"
                                        className="mt-0 mb-2"
                                        variant="outlined"
                                        onKeyDown={this.onKeyDown}
                                    />


                                    <div className="mb-3 d-flex align-items-center justify-content-between cursor-not-allowed" style={{position: 'relative'}}>
                                        <Button
                                            disabled={!isValid || loading}
                                            bg={PRIMARY_COLOR}
                                            onClick={this.handleRecover}>
                                            {<IntlMessages id="recoverPasswordBtn"/>}
                                            { loading && <CircularProgress/> }
                                        </Button>

                                        <span className={"ml-auto "}>
                                             <Link to="/signin" > {<IntlMessages id="goBack"/>} </Link>
                                        </span>

                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                    <Notification showMessage={this.state.notification.showNotification} message={this.state.notification.text} type={this.state.notification.type}/>

                    {
                        loader &&
                        <div className="loader-view">
                            <CircularProgress/>
                        </div>
                    }
                    {showMessage && NotificationManager.error(alertMessage)}
                    <NotificationContainer/>
                </div>
            </MuiThemeProvider>
        )
    }
}

const mapStateToProps = ({auth}) => {
    const {loader, alertMessage, showMessage, authUser} = auth;
    return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
    userSignUp,
})(Recover);

